.auth {
  height: 100%;
  background-color: #f9fafc;
  input:not([type="checkbox"]):not([type="button"]) {
    border: 1px solid black;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      outline: none !important;
      border: 1px solid #68402eea !important;
      box-shadow: unset;
    }
  }
  // position: relative;
  .auth-logo {
    padding-top: 49px;
    margin-left: 69px;
    @media (max-width: 600px) {
      padding-top: 30px;
      margin-left: 30px;
    }
  }
  .card {
    border: none;
    box-shadow: -20px 4px 30px rgba(0, 0, 0, 0.03);
  }

  .signin-right {
    height: 100vh;
    width: 50%;
    padding: 150px 0px;
    // position: relative;
    vertical-align: middle;
    @media (max-width: 900px) {
      width: 90%;
    }

    .signin-logo {
      display: none;
      @media (max-width: 1000px) {
        display: block;
      }
    }
    @media (min-width: 1600px) {
      position: relative;
      padding: 110px 173px 72px;
    }
    .signin-form-wrapper {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
    .signin-form-card {
      border-radius: 24px;
      @media (max-width: 380px) {
        padding: 24px !important;
        // margin: 10px 0 45px;
        h4 {
          font-size: 25px;
          font-weight: 400;
        }
      }
      @media (min-width: 1600px) {
        padding: 150px 112px !important;
        margin: 10px 0 45px;
      }

      a.login-link {
        padding: unset !important;
        margin: unset !important;
        text-decoration: none !important;
        color: #fff;
      }

      .input-check {
        margin-right: 10px;
      }
      .forgot-password {
        font-size: 16px;
        font-weight: 300;
        @media (max-width: 380px) {
          margin-top: 8px;
        }
        .forgot-password-link {
          text-decoration: none;
          color: rgba(45, 202, 115, 1);
          font-size: 16px;
          margin-left: 5px;

          //   font-weight: 500;
        }
      }
      //   .forgot-password {
      //     color: rgba(45, 202, 115, 1);
      //   }
      p {
        font-weight: 300;
        font-size: 16px;
        color: rgba(82, 87, 92, 1);
      }
      button {
        margin-top: 20px;
        background: #68402eea;
        border-radius: 16px;
        width: 100%;
        border: none;
        @media (min-width: 1600px) {
          margin: 28px 0 32px;
        }
      }
    }
  }

  .register-sec {
    height: 100vh;
    margin: auto;
    width: 50%;
    padding: 70px 0;
    @media (max-width: 900px) {
      width: 90%;
    }
    input {
      &:focus {
        outline: none;
        border: 1px solid #68402eea;
      }
    }
  }
  .signup-right {
    // position: absolute;
    // left: 0;
    // top: 0;
    // transform: translate(-50%, -50%);
    margin: 75px auto;

    width: 45%;
    display: block;
    .signup-form-header-text {
      margin: auto;
      width: 80%;
      h1 {
        font-weight: 600;
        font-size: 20px;
        color: #032041;
        opacity: 0.8;
      }
      p {
        font-size: 14px;
        color: #032041;
        opacity: 0.8;
      }
    }
    .signin-logo {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
    }
    @media (min-width: 1600px) {
      padding: 65px 87px 90px !important;
    }
    @media (max-width: 767px) {
      margin: auto;
      width: 75%;
    }
    @media (max-width: 450px) {
      margin: auto;
      width: 90%;
    }

    .signup-form-wrapper {
      width: 90%;
      .card {
        box-shadow: -20px 4px 30px rgba(0, 0, 0, 0.03);
      }
      .wizard-control-buttons {
        .btn-primary {
          background: #2062f4;
          border: none;
        }
      }
      .card-1 {
        @media (max-width: 767px) {
          display: none;
        }
        @media (min-width: 1600px) {
          padding: 113px 51px 51px 113px;
          margin: 150px -30px 0 0;
        }
        margin: 130px -30px 0 0;
        padding: 75px 40px 0px 75px;
        border-radius: 24px;
        .rc-steps-vertical .rc-steps-item-description {
          padding-bottom: 80px;
        }
        .rc-steps-item-title {
          font-size: 20px;
          font-weight: 600;
          @media (min-width: 1600px) {
            font-size: 24px;
            font-weight: 600;
          }
        }
        .rc-steps-vertical .rc-steps-item-description {
          font-weight: 300;
          @media (min-width: 1600px) {
            font-size: 20px;
            font-weight: 400;
          }
        }

        // .rc-steps-item-wait .rc-steps-item-icon {
        //     color: #fff;
        //     background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);

        // }
        // .rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
        //     color: #fff;
        //     border-color: none;

        // }
        .rc-steps-vertical .rc-steps-item-tail {
          &:after {
            background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);
            width: 2px;
          }
        }
      }
      .signup-form-card {
        @media (max-width: 767px) {
          padding: 50px 70px !important;
        }
        @media (min-width: 1600px) {
          padding: 74px 83px 60px !important;
        }
        @media (max-width: 450px) {
          padding: 30px !important;
        }
        .password-icon {
          // position: absolute;
          top: 9px !important;
          @media (min-width: 1600px) {
            top: 20px !important;
          }
          // right:15px !important;
        }
        // padding: 74px 111px !important;
        margin: 0;
        border-radius: 24px;
        .wizard-control-buttons {
          width: 70%;
          @media (max-width: 450px) {
            width: 100%;
          }
        }
        // .form {

        .dot {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          background-color: #e5e9f2;
          cursor: pointer;
          &.bg-primary {
            background-color: #2062f4;
          }
        }
        // }

        input {
          border-radius: 16px !important;
          padding: 12px 20px;
          font-size: 16px;
          margin-bottom: 17.5px;
          font-weight: 300;
          @media (min-width: 1600px) {
            padding: 24px 21px !important;
          }

          @media (max-width: 767px) {
            padding: 20px 21px !important;
            margin-bottom: 20px;
          }
          @media (max-width: 450px) {
            padding: 10px !important;
            margin-bottom: 15px;
          }
          &:focus {
            outline: none !important;
            border: 1px solid rgba(32, 98, 244);
            box-shadow: unset;
          }
        }
        .business-form {
          button {
            background: linear-gradient(
              93.88deg,
              #49a8f8 6.88%,
              #0053f4 74.45%
            );
            border-radius: 16px;
            padding: 10px;
            border: none;
          }
        }
      }
    }
  }
  .reset-password {
    height: 100vh;
    margin: auto;
    padding: 30px 0px;
    .reset-password-wrapper {
      width: 50%;
      @media (max-width: 770px) {
        width: 60% !important;
      }

      @media (max-width: 530px) {
        width: 70% !important;
      }

      @media (max-width: 440px) {
        width: 80% !important;
      }
    }
    @media (min-width: 1600px) {
      padding: 142px 0 102px;
    }
    .reset-password-text {
      h4 {
      }
      p {
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 300;
      }
    }

    .signin-form-card {
      @media (max-width: 375px) {
        padding: 40px 25px !important;
      }
      border-radius: 24px;
      input {
        border-radius: 16px !important;
        padding: 18px 20px;
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 300;
        &:focus {
          outline: none !important;
          border: 1px solid rgba(32, 98, 244);
          box-shadow: unset;
        }
      }
    }
    //   .forgot-password {
    //     color: rgba(45, 202, 115, 1);
    //   }
    p {
      font-weight: 300;
      font-size: 16px;
      color: rgba(82, 87, 92, 1);
    }
    button {
      margin-top: 20px;
      background: linear-gradient(93.88deg, #49a8f8 6.88%, #0053f4 74.45%);
      border-radius: 16px;
      width: 100%;
      border: none;
    }
  }

  .password-container {
    position: relative;
    cursor: pointer;
    .password-icon {
      position: absolute;
      top: 30px;
      right: 15px;
    }
  }
  .signup-text {
    margin: auto;
    width: 50%;
  }
  .rc-steps-item-process .rc-steps-item-icon {
    background: linear-gradient(180deg, #6ad5ed 0%, #49a8f8 100%);
  }

  .rc-steps-horizontal .rc-steps-item-description {
    display: none;
  }

  .rc-steps-item-title {
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 400;
    }
  }
}
