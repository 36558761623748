.create-transactions-section {
  .card {
    padding: 15px 0 32px 35px;
    border: none;
  }
  .card-title {
    font-weight: 500;
    font-size: 24px;
  }
  .upload-pic-container{
    background: #D1D1F9;
    border-radius: 8px;
    border:none !important;
    padding:22.44px;
  }
  .password-container {
    position: relative;
    cursor: pointer;
    .password-icon {
      position: absolute;
      top: 5px;
      right: 15px;
    }
  }
  
}
.transaction-outline-danger{
  border: solid 1px #EE0000 !important;
}
