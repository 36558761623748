.contact-section {
    .hero-section {
      background: url("../img/c2.jpeg");
      background-repeat: no-repeat;
      background-position: 0 48%;
      background-attachment: fixed;
      background-size: cover;
      height: 50vh;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.72);
      position: relative;
      @media (max-width: 767px) {
        background-position: -105px 11px;
        background-attachment: unset;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.62);
  
      }
      .hero-cta-section {
        position: absolute;
        left: 30%;
        top: 40%;
        width: 100% !important;
        @media (max-width: 767px) {
          left: unset;
        }
      }
    }
}