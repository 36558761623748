body {
  font-family: "DM Sans", sans-serif !important;
  background: #f3f4f6 !important;
}
button {
  &:focus {
    outline: none !important;
  }
}

table {
  border-collapse: collapse;
  th {
    background: white;
    padding: 11px 19px;
    font-size: 16px;
    font-weight: lighter;
    line-height: 26px;
    color: #8b8b8b;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
  td {
    padding: 11px 19px;
  }
}

a {
  text-decoration: none !important;
  color: black !important;
}
.css-1pahdxg-control {
  border-color: #68402eea !important;
  box-shadow: unset !important;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #68402eea;
}

input[type="button"] {
  outline: none !important;
}
input:focus {
  outline: none !important;
}



button:hover,
button:focus {
  outline: none;
}
.btn-secondary:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
table {
  border-collapse: collapse;
  th {
    background: #68402eea;
    padding: 11px 19px;
    font-size: 16px;
    font-weight: lighter;
    line-height: 26px;
    color: white;
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
  td {
    padding: 11px 19px;
  }
}

.user-outline-btn {
  color: #68402eea !important;
  border: solid #68402eea 1px !important;
  background: transparent;
  &:hover {
    background: #68402eea !important;
    color: white !important;
  }
}

// input {
  
// }
