
.modal {
  backdrop-filter: blur(3px) !important;
  border: none;
}
.modal-backdrop {
  background: rgba(29, 30, 44, 0.32) !important;
  // opacity:0.9em;
  backdrop-filter: blur(50px) !important;
}
.modal-content {
  border-radius: 8px !important;
  padding: 32px 49px;
  border: none !important;
}
.modal-cstm-button {
  padding-right: 8px;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #c0ccda;
  color: #fff;
}

.top-right-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(29, 30, 44, 0.32) !important;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(4px) !important;

  .top-right-modal-content {
    // background-color: #fff;
    position: absolute;
    right: 98px;
    top: 25px;
    @media (max-width: 800px) {
      right: 55px;
      top: 18px;
    }
    @media (max-width: 500px) {
      right: 35px;
    }
    // border-radius: 40px !important;
    // padding: 50px 45px;
  }
}
