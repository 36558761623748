.dashboard-section {
  .dashboard-card {
    padding: 50px 0 16px 25px;
    border:none
  }
  .card-title {
    font-size: 16px;
    color: black;
  }
  .card-curr{
    font-size: 23px;
  }
  .card-value{
    font-weight: 400;
    font-size: 22px;
    color:#68402eea


  }
  .transaction-card{
    border:none;
  }
  .dashboard-outline-btn{
    border: solid 1px #EE0000 !important;

  }
}
