.hero-section {
  background: url("../img/hero2.JPG");
  background-repeat: no-repeat;
  background-color: center;
  background-size: cover;
  height: calc(100vh - 10.3vh);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.69);
  position: relative;
  @media (max-width: 900px) {
    height: 40vh;
    background-position: center;
  }
  .hero-cta-section {
    position: absolute;
    top: 30%;
    left: 0;
    text-align: left;
    vertical-align: middle;
    padding: 0 2em;
    width: 55%;
    input {
      width: 80%;
      border: none;
      &:focus {
        outline: none;
      }
    }
    @media (max-width: 900px) {
      width: unset;
    }
    button {
      width: 20%;
    }
    h3 {
      //   font-family: "Inter", sans-serif;
      color: white;
      font-weight: 700;
      //   font-family: "Alfa Slab One", cursive;
      font-size: 2.5rem;
      @media (max-width: 900px) {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 12px;
      }
    }
  }
}

.zoom-container {
  position: relative;
  &:hover {
  }
  .zoom {
    &:hover {
      transform: scale(1.1);

      cursor: zoom-in;
    }
  }

  .product-countdown {
    position: absolute;
    text-align: center;
    bottom: 0;
    background: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: blur(6px);
    font-size: 1.6rem;
    font-weight: 700;
  }
}

.product-details-countdown {
  text-align: center;
  bottom: 0;
  background: hsla(0, 0%, 100%, 0.8);
  backdrop-filter: blur(6px);
  font-size: 1.6rem;
  font-weight: 700;
}
