.exhibition {
  .exhibition-sidebar {
    margin: 30px 0;
    position: fixed;
    width: 250px;
    background: white;
    height: calc(100vh - 20vh);
  }
  .exhibtion-content {
    margin: 30px 39px 266px 270px;
    position: absolute;
    @media (max-width: 768px) {
      margin: unset;
      position: unset;
      margin-top: 35px;
    }
  }
  .product-details {
    position: absolute;
    bottom: 0;
    background: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: blur(6px);
    font-size: 0.9rem;
    font-weight: 300;
  }
}
