.model-section {
  .hero-section {
    background: url("../img/md1.jpeg");
    background-repeat: no-repeat;
    background-position: left;
    background-attachment: fixed;
    background-size: cover;
    height: 50vh;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.52);
    position: relative;
    @media (max-width: 767px) {
      background-position: -105px 11px;
      background-attachment: unset;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.62);

    }
    .hero-cta-section {
      position: absolute;
      left: 30%;
      top: 50%;
      width: 100% !important;
      @media (max-width: 767px) {
        left: unset;
      }
    }
  }

  .model-form {
    width: 75%;
    margin: 0 auto;
    @media (max-width:767px) {
        width:85%;
        
    }
    input {
      border: hsl(0, 0%, 80%) solid 1px;
      outline: #68402eea;
    }
    button {
      background: #68402eea;
      color: white;
    }
  }
}
